body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.nomcom{
  color: #fff;
}

.lista ul li{
  text-align: left;
  text-justify: inter-word;
  
}
.flags{
  width:75px;
  display:flex;
  align-items:center;
  justify-content:space-between;

}
.flags_item{
  width:30px;
  cursor:pointer;
  }
.flags_img:hover{
  border-radius: 50%;
}